import React, { useEffect, useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Button,
  CssBaseline,
  TextField,
  Paper,
  Box,
  Grid,
  Typography,
  createTheme,
  ThemeProvider,
  CircularProgress,
  Backdrop,
  InputAdornment,
  IconButton
} from '@mui/material';
import { ReactComponent as CarmaLogo } from '../../../assets/images/carma_logo_white.svg';
import loginImage from '../../../assets/images/loginImage.png';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchLoggedInUser, loginUser } from '../../../redux/actions/user';
import { Account } from '../../../models/account';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { unwrapResult } from '@reduxjs/toolkit';
import { APP_FONT } from '../../../constants/app_font';
import './login.css';


type LoginBase = {
  email: string;
  password: string;
};

const theme = createTheme({
  typography: {
    fontFamily: APP_FONT,
  },
});

const Login = () => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const cognitoId = localStorage.getItem('cognitoId');

    if (cognitoId) {
      dispatch<any>(fetchLoggedInUser(cognitoId))
        .then(unwrapResult)
        .then((user: any) => {
          if (user.cognito_id && user.userToken) {
            navigate('dashboard');
          }
        })
        .catch((rejectedValueOrSerializedError: any) => {
          localStorage.removeItem('cognitoId')
          console.log('error');
        });
    }
    console.log("loop?")
  }, []);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<LoginBase>({
    shouldUseNativeValidation: true,
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const submit: SubmitHandler<any> = async (login: LoginBase) => {
    setError(null);
    setLoading(true);
    const user: Account = {
      email: login.email,
      password: login.password,
    };
    dispatch<any>(loginUser(user))
      .then(unwrapResult)
      .then((userInfo: any) => {
        setLoading(false);
        if (userInfo.userToken) {
          navigate('/dashboard');
        } else {
          setError(userInfo);
        }
      })
      .catch((err: any) => {
        setLoading(false);
        setError(err);
      });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={6}
          md={6}
          sx={{
            backgroundImage: `url(${loginImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light'
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: '#FF6600',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          sm={6}
          md={6}
          component={Paper}
          elevation={6}
          square
        >
          <CarmaLogo width={275} height={79} />
          <Box
            sx={{
              mx: 8,
              my: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: 'white',
              borderRadius: '4px',
              padding: '4%',
            }}
          >
            <Typography
              component="h6"
              variant="h6"
              fontWeight="bold"
              fontFamily={'Figtree'}
              fontSize="14px"
            >
              Welcome, please sign in.
            </Typography>
            {error && (
              <Typography fontSize="14px" color="error">
                {error}
              </Typography>
            )}
            <Box component="form">
              <Controller
                name="email"
                control={control}
                rules={{
                  required: 'Please enter your email.',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email format',
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value.toLowerCase());
                    }}
                    type="text"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    InputLabelProps={{
                      style: { fontFamily: 'Figtree' },
                    }}
                    autoFocus
                  />
                )}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '412px',
                }}
              >
                <Controller
                  name="password"
                  control={control}
                  rules={{ required: 'Please enter your password.' }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      type={showPassword ? 'text' : 'password'}
                      label="Password"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      error={!!errors.password}
                      helperText={errors.password?.message}
                      onChange={onChange}
                      value={value}
                      InputLabelProps={{ style: { fontFamily: 'Figtree' } }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff color='action' /> : <Visibility color='action' />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
                <p
                  className="forgot"
                  onClick={() => {
                    navigate('/forgot-password');
                  }}
                >
                  Forgot your password?
                </p>
              </div>
              <Button
                onClick={handleSubmit(submit)}
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 2,
                  mb: 1,
                  borderRadius: 10,
                  backgroundColor: 'black',

                  fontFamily: 'Figtree',
                }}
              >
                LET'S GO
              </Button>
            </Box>
          </Box>
          {isLoading && (
            <Backdrop
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
              onClick={() => { }}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Login;
