import React, { useCallback, useState } from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Toolbar,
  Paper,
  IconButton,
  Button,
  styled,
  List,
  ListItem,
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import socket from '../../../sockets/socket'
import { APP_FONT } from '../../../constants/app_font'
import { ReactComponent as CurrentOffersIcon } from '../../../assets/images/current_offers_icon.svg'
import HoverPopper from '../../../components/Hover/hover_popper'
import { formatDateTime } from '../../../utils/helper'
import { fetchOffers } from '../../../redux/actions/offer'
import SetUpPickUpModal from './modals/set_pick_up_modal'
import Automated from '../../../assets/images/automated_icon.svg'
import useLocalTimezoneDates from '../../../utils/localizeTimezone'
import Copy from '../../../assets/images/contentCopy.svg'
import CopyFilled from '../../../assets/images/contentCopyFilled.svg'
import { Offer } from '../../../models/offer_new'
import EditIcon from '@mui/icons-material/Edit'
import EditOfferModal from './modals/edit_offer_modal'
import { InfoChip } from './modals/components/ui/info_chip'
import { ArrowTooltip } from './modals/components/ui/arrow_tooltip'

interface Data {
  // AutomatedImg: string
  RequestID: string
  Vehicle: string
  VehicleId: string
  VIN: string
  VehicleClass: string
  EngineType: string
  EngineSize: string
  Services: string
  OfferAmount: string
  Timeblock: string
  OfferPool: string
  PickupTime: string
  Comments: string
  SpecialHandling: string
  EditAction: string
  // Action: string
}

const StickyHeaderTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  top: 0,
  zIndex: 10,
  position: 'sticky',
  boxShadow: '0 2px 2px -1px rgba(0,0,0,0.4)',
}))

const StyledButton = styled(IconButton)`
  & {
    width: 35px;
    height: 35px;
    margin: 12px;
  }
  ,
  &&:hover {
    width: 35px;
    height: 35px;
    background-color: #fff0eb;
    margin: 12px;
  }
`

const rows = []

type Order = 'asc' | 'desc'

interface HeadCell {
  disablePadding: boolean
  id: keyof Data
  label: string
}

const headCells: readonly HeadCell[] = [
  // {
  //   id: 'AutomatedImg',
  //   disablePadding: false,
  //   label: '',
  // },
  {
    id: 'RequestID',
    disablePadding: true,
    label: 'Request ID',
  },
  {
    id: 'Vehicle',
    disablePadding: false,
    label: 'Vehicle',
  },
  {
    id: 'VehicleId',
    disablePadding: false,
    label: 'Vehicle ID',
  },
  {
    id: 'VIN',
    disablePadding: false,
    label: 'VIN',
  },
  {
    id: 'VehicleClass',
    disablePadding: false,
    label: 'Vehicle class',
  },
  {
    id: 'EngineType',
    disablePadding: false,
    label: 'Engine type',
  },
  {
    id: 'EngineSize',
    disablePadding: false,
    label: 'Engine size',
  },
  {
    id: 'Services',
    disablePadding: false,
    label: 'Services',
  },
  {
    id: 'OfferAmount',
    disablePadding: false,
    label: 'Offer amount',
  },
  {
    id: 'Timeblock',
    disablePadding: false,
    label: 'Drop-off time',
  },
  {
    id: 'PickupTime',
    disablePadding: false,
    label: 'Pick up time',
  },
  {
    id: 'Comments',
    disablePadding: false,
    label: 'Comments',
  },
  {
    id: 'SpecialHandling',
    disablePadding: false,
    label: 'Special handling requirements',
  },
  {
    id: 'EditAction',
    disablePadding: false,
    label: 'Edit',
  },
  // {
  //   id: 'Action',
  //   disablePadding: false,
  //   label: '',
  // },
]

const getDropOffDateTime = (service_request: any) => {
  if (
    !service_request.drop_off_date ||
    !service_request.drop_off_time ||
    service_request.drop_off_time.length === 0
  ) {
    console.error('Invalid drop-off date or time')
    return null
  }
  const [datePart] = service_request.drop_off_date.split('T')
  const timeStr = service_request.drop_off_time[0].split(' ')[0]

  const [year, month, day] = datePart.split('-').map((num: any) => parseInt(num, 10))
  const period = service_request.drop_off_time[0].split(' ')[1]
  let [hours, minutes] = timeStr.split(':').map((str: string) => parseInt(str, 10))

  if (period === 'PM' && hours < 12) hours += 12
  if (period === 'AM' && hours === 12) hours = 0

  const dropOffDate = new Date(year, month - 1, day, hours, minutes, 0, 0)

  return dropOffDate.toISOString()
}

interface EnhancedTableProps {
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  order: Order
  orderBy: string
  rowCount: number
}

type Props = {
  formatCurrency: any
}

export default function CurrentOffers(props: Props) {
  const { formatCurrency } = props
  const [order] = useState<Order>('asc')
  const [orderBy] = useState<keyof Data>('RequestID')
  const [selected] = useState<readonly string[]>([])
  const [currentOffers, setCurrentOffers] = useState<Offer[]>([])
  const [selectedOffer, setSelectedOffer] = useState<Offer | null>()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [copiedVin, setCopiedVin] = React.useState<string | null>(null)
  const [copiedComment, setCopiedComment] = React.useState<string | null>(null)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [editingOffer, setEditingOffer] = useState<Offer | null>(null)
  const [alert, setAlert] = useState(false)

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy } = props
    const hasDiagnostic = currentOffers.some(
      (offer) => offer.consumer_request.type === 'DIAGNOSTIC'
    )

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => {
            if (headCell.id === 'EditAction' && hasDiagnostic) {
              return null
            }
            return (
              <StickyHeaderTableCell
                key={headCell.id}
                align={'left'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <div style={{ fontWeight: '600', fontFamily: APP_FONT }}>{headCell.label}</div>
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </StickyHeaderTableCell>
            )
          })}
        </TableRow>
      </TableHead>
    )
  }

  const EnhancedTableToolbar = () => {
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        ></Typography>
      </Toolbar>
    )
  }

  const localCurrentOffers = useLocalTimezoneDates(currentOffers, [
    'provider_drop_off_by',
    'drop_off_by',
    'pick_up_after',
  ])

  const handleCopyVin = (event: React.MouseEvent, vin: string) => {
    event.stopPropagation()

    navigator.clipboard
      .writeText(vin)
      .then(() => {
        setCopiedVin(vin)
        setTimeout(() => {
          setCopiedVin(null)
        }, 3000)
      })
      .catch((err) => console.error('Failed to copy text: ', err))
  }

  const handleCopyComment = (event: React.MouseEvent, comment: string) => {
    event.stopPropagation()

    navigator.clipboard
      .writeText(comment)
      .then(() => {
        setCopiedComment(comment)
        setTimeout(() => {
          setCopiedComment(null)
        }, 3000)
      })
      .catch((err) => console.error('Failed to copy text: ', err))
  }

  const getCurrentOffers = () => {
    const providerId = localStorage.getItem('branchId')
    if (providerId)
      dispatch<any>(fetchOffers({ statuses: ['REQUESTED'], providerId }))
        .then(unwrapResult)
        .then((offers: any) => {
          setCurrentOffers(offers)
          const mappedOffers = offers.map((offer: Offer) => {
            return {
              ...offer,
              requestedDateTime: getDropOffDateTime(offer.consumer_request),
            }
          })
          setCurrentOffers(mappedOffers)
        })
        .catch((rejectedValueOrSerializedError: any) => {
          console.log('error')
        })
  }

  React.useEffect(() => {
    getCurrentOffers()

    const providerId = localStorage.getItem('branchId')
    if (providerId) {
      socket.emit('subscribe', { branchId: providerId })
      socket.on('status-update', (payload) => {
        getCurrentOffers()
      })
    }

    return () => {
      socket.off('status-update')
    }
  }, [])

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = useCallback((offer: Offer) => {
    setOpen(true)
    setSelectedOffer(offer)
  }, [])

  const handleOpenEditModal = useCallback((offer: Offer) => {
    setSelectedOffer(offer)
    setEditingOffer(offer)
    setEditModalOpen(true)
  }, [])

  const handleCloseEditModal = () => {
    setEditModalOpen(false)
    setEditingOffer(null)
  }

  return (
    <Box sx={{ mb: 5 }}>
      <Box
        sx={{
          marginLeft: 2,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          marginBottom: -8,
        }}
      >
        <CurrentOffersIcon />
        <Typography
          sx={{
            marginTop: 4,
            fontFamily: APP_FONT,
            fontSize: 14,
            letterSpacing: 1,
            textTransform: 'uppercase',
            color: '#AEAEAE',
          }}
          id="tableTitle"
          component="div"
        >
          Current Offers
        </Typography>
      </Box>
      <Paper>
        <EnhancedTableToolbar />
        <TableContainer sx={{ px: 2 }}>
          <Table aria-labelledby="tableTitle" size={'small'}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              rowCount={rows.length}
              onRequestSort={function (
                event: React.MouseEvent<unknown, MouseEvent>,
                property: keyof Data
              ): void {
                throw new Error('Function not implemented.')
              }}
              onSelectAllClick={function (event: React.ChangeEvent<HTMLInputElement>): void {
                throw new Error('Function not implemented.')
              }}
            />
            <TableBody>
              {localCurrentOffers
                ? localCurrentOffers.map((row: Offer) => (
                    <TableRow key={row.id}>
                      {/* <TableCell align="left">
                        {row.is_automated && (
                          <ArrowTooltip title="Automated offer">
                            <IconButton>
                              <img src={Automated} alt="Automated" />
                            </IconButton>
                          </ArrowTooltip>
                        )}
                      </TableCell> */}
                      <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                        {row.consumer_request.slug ?? row.consumer_request.id}
                      </TableCell>
                      <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                        {`${row.vehicle?.year} ${row.vehicle?.make} ${row.vehicle?.model} ${
                          row.vehicle?.trim ?? ''
                        }` || 'Null'}
                      </TableCell>
                      <TableCell sx={{ fontFamily: APP_FONT }}>{row.vehicle?.name}</TableCell>
                      <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
                          {row.vehicle?.vin || 'VIN not provided'}
                          {row.vehicle?.vin && (
                            <ArrowTooltip title={copiedVin === row.vehicle?.vin ? 'Copied!' : ''}>
                              <StyledButton
                                onClick={(event) => handleCopyVin(event, row.vehicle?.vin ?? '')}
                              >
                                <img
                                  src={copiedVin === row.vehicle?.vin ? CopyFilled : Copy}
                                  alt=""
                                />
                              </StyledButton>
                            </ArrowTooltip>
                          )}
                        </div>
                      </TableCell>
                      <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                        {row.vehicle?.class?.name ? `Class ${row.vehicle?.class?.name}` : ''}
                      </TableCell>
                      <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                        {[row.vehicle?.engines].join(', ')}
                      </TableCell>
                      <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                        {row.vehicle?.engine_size}
                      </TableCell>
                      <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                        <Box>
                          <List sx={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                            {(row?.services?.length > 0
                              ? row?.services?.slice(0, 3)
                              : [{ service: { name: 'Full general diagnostic' } }]
                            ).map((item, index) => {
                              let displayName = item?.service.name
                              if (
                                item?.service?.parent &&
                                item.service.parent.parent_id &&
                                item.service.parent.name
                              ) {
                                displayName = `${item.service.parent.name} (${displayName}${
                                  item.service.type === 'TIRE' &&
                                  row.vehicle.tires &&
                                  row.vehicle.tires.length > 0
                                    ? `, ${row.vehicle.tires[0].width}/${row.vehicle.tires[0].ratio}${row.vehicle.tires[0].construction}${row.vehicle.tires[0].diameter}`
                                    : ''
                                })`
                              }

                              return (
                                <ListItem
                                  key={`a-${item.service.name}-${index}`}
                                  sx={{ display: 'list-item', padding: 0 }}
                                >
                                  <Typography mb={1.5} fontFamily={APP_FONT} fontSize={'14px'}>
                                    {displayName}
                                  </Typography>
                                </ListItem>
                              )
                            })}
                          </List>
                        </Box>

                        {row?.services?.length - 3 > 0 && (
                          <HoverPopper
                            holder={
                              <Typography
                                sx={{
                                  color: '#DB5D08',
                                  textAlign: 'center',
                                  cursor: 'pointer',
                                }}
                              >
                                {`(${row?.services?.length || 1}) View all`}
                              </Typography>
                            }
                          >
                            <Box
                              sx={{
                                backgroundColor: 'rgba(238, 238, 238, 0.95)',
                                pointerEvents: 'auto',
                                paddingTop: 3,
                                paddingLeft: 3,
                                paddingRight: 3,
                                paddingBottom: 1,
                              }}
                            >
                              {row.services.map((item, index) => {
                                let displayName = item?.service.name
                                if (
                                  item?.service?.parent &&
                                  item.service.parent.parent_id &&
                                  item.service.parent.name
                                ) {
                                  displayName = `${item.service.parent.name} (${displayName}${
                                    item.service.type === 'TIRE' &&
                                    row.vehicle.tires &&
                                    row.vehicle.tires.length > 0
                                      ? `, ${row.vehicle.tires[0].width}/${row.vehicle.tires[0].ratio}${row.vehicle.tires[0].construction}${row.vehicle.tires[0].diameter}`
                                      : ''
                                  })`
                                }

                                return (
                                  <Typography
                                    key={`a-${item.service.name}-${index}`}
                                    mb={1.5}
                                    fontFamily={APP_FONT}
                                    fontSize={'14px'}
                                  >
                                    {displayName}
                                  </Typography>
                                )
                              })}
                            </Box>
                          </HoverPopper>
                        )}
                      </TableCell>
                      <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                        {formatCurrency.format(row.total_fee) || 'Null'}
                      </TableCell>
                      <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                        {row.provider_drop_off_by
                          ? formatDateTime(row.provider_drop_off_by)
                          : formatDateTime(row.drop_off_by) || 'Not Selected'}
                      </TableCell>
                      <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                        {formatDateTime(row.pick_up_after) || 'Not Selected'}
                      </TableCell>
                      <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
                          {row.consumer_request.additional_information ? (
                            <>
                              {row.consumer_request.additional_information.length > 104 ? (
                                <HoverPopper
                                  holder={
                                    <span>
                                      {row.consumer_request.additional_information.substring(
                                        0,
                                        104
                                      )}
                                      <span style={{ color: '#FF7D26' }}>...</span>
                                    </span>
                                  }
                                >
                                  <Box
                                    sx={{
                                      width: '500px',
                                      textAlign: 'center',
                                      backgroundColor: 'rgba(238, 238, 238, 0.95)',
                                      pointerEvents: 'auto',
                                      p: 2,
                                    }}
                                  >
                                    {row.consumer_request.additional_information}
                                  </Box>
                                </HoverPopper>
                              ) : (
                                row.consumer_request.additional_information
                              )}
                              <ArrowTooltip
                                title={
                                  copiedComment === row.consumer_request.additional_information
                                    ? 'Copied!'
                                    : ''
                                }
                              >
                                <StyledButton
                                  onClick={(e: any) =>
                                    handleCopyComment(
                                      e,
                                      row.consumer_request.additional_information ?? ''
                                    )
                                  }
                                >
                                  <img
                                    src={
                                      copiedComment === row.consumer_request.additional_information
                                        ? CopyFilled
                                        : Copy
                                    }
                                    alt="Copy icon"
                                  />
                                </StyledButton>
                              </ArrowTooltip>
                            </>
                          ) : (
                            'No additional information'
                          )}
                        </div>
                      </TableCell>
                      <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
                          {row.vehicle.special_handlings &&
                          row.vehicle.special_handlings.length > 0 ? (
                            <HoverPopper
                              holder={<InfoChip label={row.vehicle.special_handlings[0].title} />}
                            >
                              <Box
                                sx={{
                                  width: '500px',
                                  textAlign: 'center',
                                  backgroundColor: 'rgba(238, 238, 238, 0.95)',
                                  pointerEvents: 'auto',
                                  paddingTop: 1,
                                  paddingLeft: 3,
                                  paddingRight: 3,
                                  paddingBottom: 1,
                                }}
                              >
                                {' '}
                                {row.vehicle.special_handlings[0].description}
                              </Box>
                            </HoverPopper>
                          ) : (
                            'N/A'
                          )}
                        </div>
                      </TableCell>
                      {row.consumer_request.type !== 'DIAGNOSTIC' ? (
                        <TableCell>
                          <IconButton
                            size="small"
                            onClick={() => handleOpenEditModal(row)}
                            aria-label="edit pick-up time"
                          >
                            <EditIcon fontSize="inherit" />
                          </IconButton>
                        </TableCell>
                      ) : (
                        ''
                      )}

                      {/* <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                        {row.is_automated && (
                          <Button
                            sx={{
                              minWidth: 200,
                              borderRadius: 24,
                              backgroundColor: '#FFFFFF',
                              fontSize: 12,
                              fontWeight: 700,
                              textTransform: 'none',
                              fontFamily: 'Figtree',
                              color: '#FF6600',
                              border: '2px solid #FF6600',
                              '&:hover': {
                                color: '#FFFFFF',
                                borderColor: '#1976d2',
                              },
                            }}
                            variant="contained"
                            onClick={() => handleOpen(row)}
                            disabled={row.pick_up_after_is_verified}
                          >
                            Override pick-up time
                          </Button>
                        )}
                      </TableCell> */}
                    </TableRow>
                  ))
                : ''}
            </TableBody>
          </Table>

          {selectedOffer && selectedOffer.is_automated && (
            <SetUpPickUpModal
              setOpen={setOpen}
              open={open}
              onHide={handleClose}
              offer={selectedOffer}
            />
          )}
          {selectedOffer && (
            <EditOfferModal
              setOpen={setEditModalOpen}
              open={editModalOpen}
              onHide={handleCloseEditModal}
              offer={selectedOffer}
              alert={alert}
              setAlert={setAlert}
            />
          )}
        </TableContainer>
      </Paper>
    </Box>
  )
}
