import React, { useState, useRef, useEffect } from 'react'
import {
  Dialog,
  DialogContent,
  Button,
  Typography,
  Grid,
  Box,
  IconButton,
  TextField,
  MenuItem,
  Divider,
  Stack,
  InputAdornment,
  Alert,
  CircularProgress,
  Backdrop,
} from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import {
  createReccomendedServices,
  submitTechnicalApproval,
  updateOfferStatus,
  uploadPdf,
} from '../../../../redux/actions/offer'
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import ModalTableHead from './components/table_head'
import 'react-datepicker/dist/react-datepicker.css'
import { APP_FONT } from '../../../../constants/app_font'
import { Offer } from '../../../../models/offer_new'
import DeleteIcon from '../../../../assets/images/delete.svg'
import Autocomplete from '@mui/material/Autocomplete'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as DownloadIcon } from '../../../../assets/images/download.svg'
import { ReactComponent as PdfDownloadIcon } from '../../../../assets/images/pdf_download.svg'
import { ReactComponent as Close } from '../../../../assets/images/close.svg'
import InspectionWithoutServicesModal from './inspection_without_services'
import { formatPrice } from '../../../../components/helper/helper'
import ErrorHandler from '../../error-handler'

const MAX_FILE_SIZE = 25 * 1024 * 1024

interface ServiceChild {
  id: string
  name: string
}

interface Service {
  id: string
  name: string
  children: ServiceChild[]
}

interface RecommendedService {
  id: string
  name: string
  type: string
  types: any[]
  price: number
  serviceType: string
}

interface InspectionUploadModalProps {
  setOpen: (open: boolean) => void
  open: boolean
  onClose: () => void
  onSubmit: () => void
  offer: Offer
  services: Service[]
}

interface ServicePrices {
  [serviceId: string]: string
}

const InspectionUploadModal: React.FC<InspectionUploadModalProps> = ({
  setOpen,
  open,
  onClose,
  onSubmit,
  offer,
  services,
}) => {
  const dispatch = useDispatch()
  const [error, setError] = useState<string | null>(null)
  const [fileWorkOrder, setFileWorkOrder] = useState<File | null>(null)
  const [fileInspection, setFileInspection] = useState<File | null>(null)
  const [filePmi, setFilePmi] = useState<File | null>(null)
  const [uploadStatusPmi, setUploadStatusPmi] = useState<
    'IDLE' | 'UPLOADING' | 'SUCCESS' | 'ERROR'
  >('IDLE')
  const [uploadStatusInspection, setUploadStatusInspection] = useState<
    'IDLE' | 'UPLOADING' | 'SUCCESS' | 'ERROR'
  >('IDLE')
  const [uploadStatusWorkOrder, setUploadStatusWorkOrder] = useState<
    'IDLE' | 'UPLOADING' | 'SUCCESS' | 'ERROR'
  >('IDLE')
  const navigate = useNavigate()
  const [recServices, setRecServices] = useState<RecommendedService[]>([])
  const fileInputInspectionRef = useRef<HTMLInputElement>(null)
  const fileInputPmiRef = useRef<HTMLInputElement>(null)
  const [servicePrices, setServicePrices] = useState<ServicePrices>({})
  const [fee, setFee] = useState<string>()
  const [totalFee, setTotalFee] = useState<string>()
  const [shopSupplyFees, setShopSupplyFees] = useState<string>('')
  const [tax, setTax] = useState<string>('')
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertText, setAlertText] = useState('')
  const [inspectionFeedbackFile, setInspectionFeedbackFile] = useState<any | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isInspectionWithoutServicesModalOpen, setInspectionWithoutServicesModalOpen] =
    useState(false)
  const [showAdditionalServices, setShowAdditionalServices] = useState(false)
  const [options, setOptions] = useState<Service[]>(services)
  const [loading, setLoading] = useState<boolean>(false)

  const fetchServices = async (query: string) => {
    if (query) {
      setLoading(true)
      setOptions([])
      try {
        const response = await fetch(
          `${process.env.REACT_APP_NEST_JS_API}service?level=2&search=${query}`
        )
        const data = await response.json()

        const filteredOptions = data.items.filter(
          (item: any) =>
            item.name.toLowerCase().includes(query.toLowerCase()) && item.parent_id !== null
        )

        setOptions(filteredOptions)
      } catch (error) {
        console.error('Failed to fetch services:', error)
      } finally {
        setLoading(false)
      }
    } else {
      setOptions(services)
    }
  }

  const handleInputChange = (event: React.SyntheticEvent, newInputValue: string) => {
    fetchServices(newInputValue)
  }

  const clearError = () => setError(null)

  const handleUploadClick = (type: string) => {
    type === 'WORK_ORDER'
      ? fileInputInspectionRef.current?.click()
      : fileInputPmiRef.current?.click()
  }

  const handleServicePriceChange = (
    serviceId: string,
    serviceType: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = event.target.value.replace(/[^0-9]/g, '')
    const number = parseInt(input, 10) / 100

    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(number)
      .slice(1)

    setServicePrices((prevPrices) => ({
      ...prevPrices,
      [`${serviceId}_${serviceType}`]: formattedNumber,
    }))
  }

  useEffect(() => {
    const newTotal = calculateSubTotalPrice()
    const formattedTotal = newTotal.toFixed(2)
    setFee(formattedTotal)
  }, [servicePrices, recServices, offer.services])

  useEffect(() => {
    const initialRecommendedServices = offer?.services
      .filter((service) => !service.is_approved)
      .map((service) => {
        const serviceId = service.service.parent.id || service.service.id
        const matchingService = services.find(
          (s) => s.id === service.service.parent.id || s.id === service.service.id
        )
        if (matchingService) {
          const formattedNumber = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
            .format(service.price)
            .slice(1)

          setServicePrices((prevPrices) => ({
            ...prevPrices,
            [`${serviceId}_${service.service.id}`]: formattedNumber,
          }))

          return {
            id: service.service.parent.id,
            name: matchingService.name,
            type: service.service.id,
            types: matchingService.children,
            price: service.price,
            serviceType: service.service.type,
          }
        }
        return null
      })
      .filter((service) => service !== null) as RecommendedService[]

    setRecServices(initialRecommendedServices)
  }, [offer.services, services])

  const calculateSubTotalPrice = (): number => {
    const offerServiceTotal = offer.services
      .filter((service) => service.is_approved)
      .reduce((total, service) => {
        return total + (service.price || 0)
      }, 0)

    const recServiceTotal = recServices.reduce((total, service) => {
      const priceStr = servicePrices[`${service.id}_${service.type}`] || '0'
      const cleanPriceStr = priceStr.replace(/[^\d.]/g, '')
      const priceNumber = parseFloat(cleanPriceStr)

      if (!isNaN(priceNumber)) {
        const roundedPrice = Math.round(priceNumber * 100) / 100
        return total + roundedPrice
      }
      return total
    }, 0)

    return offerServiceTotal + recServiceTotal
  }

  useEffect(() => {
    function calculateTotalPrice() {
      const fees = parseFloat(shopSupplyFees?.replace(/,/g, '')) || 0
      const taxes = parseFloat(tax?.replace(/,/g, '')) || 0
      const subtotal = calculateSubTotalPrice()

      return (subtotal + fees + taxes).toFixed(2)
    }

    const total = calculateTotalPrice()
    setTotalFee(total)
  }, [recServices, servicePrices, shopSupplyFees, fee, tax, offer.services])

  useEffect(() => {
    if (offer?.files) {
      const feedbackFile = offer.files.find((file) => file.type === 'WORK_ORDER_FEEDBACK')
      setInspectionFeedbackFile(feedbackFile || null)
    }
  }, [offer])

  const handleFileSelect = (selectedFile: File, fileType: string) => {
    if (selectedFile.size > MAX_FILE_SIZE) {
      setAlertOpen(true)
      setAlertText('File is too large. Maximum size is 10MB.')
      return
    }
    if (fileType === 'WORK_ORDER') {
      setFileWorkOrder(selectedFile)
    } else if (fileType === 'PMI') {
      setFilePmi(selectedFile)
    } else if (fileType === 'INSPECTION') {
      setFileInspection(selectedFile)
    }
    fileType === 'WORK_ORDER' ? setFileWorkOrder(selectedFile) : setFilePmi(selectedFile)
    setUploadStatusWorkOrder('IDLE')
  }

  const handleUpload = async () => {
    await handleUploadWorkOrder()
    if (isPmi) {
      await handleUploadPmi()
    } else {
      await handleUploadInspection()
    }
  }

  const handleUploadWorkOrder = async () => {
    if (!fileWorkOrder || !offer.id) {
      console.error('No file or offerId provided.')
      setUploadStatusWorkOrder('ERROR')
      return
    }
    setUploadStatusWorkOrder('UPLOADING')
    const formData = new FormData()
    formData.append('file', fileWorkOrder)

    try {
      dispatch<any>(uploadPdf({ offerId: offer.id, fileType: 'WORK_ORDER', formData }))
        .then(unwrapResult)
        .then((data: any) => {
          setUploadStatusWorkOrder('SUCCESS')
          if (fileInspection) {
            return
          }
          handleSubmit()
        })
    } catch (error: any) {
      console.error('Error uploading file:', error)
      setUploadStatusWorkOrder('ERROR')
      setAlertText(error ?? 'ERROR on file upload')
    }
  }

  const handleUploadInspection = async () => {
    if (!fileInspection || !offer.id) {
      console.error('No file or offerId provided.')
      setUploadStatusInspection('ERROR')
      return
    }
    setUploadStatusInspection('UPLOADING')
    const formData = new FormData()
    formData.append('file', fileInspection)

    dispatch<any>(uploadPdf({ offerId: offer.id, fileType: 'INSPECTION', formData }))
      .then(unwrapResult)
      .then((data: any) => {
        setUploadStatusInspection('SUCCESS')
        handleSubmit()
      })
      .catch((error: any) => {
        console.error('Error uploading file:', error)
        setUploadStatusInspection('ERROR')
        setError(`Upload failed: ${error.message}`)
        setAlertText(error.message)
      })
  }

  const onConfirmWithoutService = async () => {
    if (isPmi) {
      handleUploadPmi()
    } else {
      confirmWithoutService()
    }
  }

  const handleUploadPmi = async () => {
    if (!filePmi || !offer.id) {
      console.error('No file or offerId provided.')
      setUploadStatusPmi('ERROR')
      return
    }
    setUploadStatusPmi('UPLOADING')
    const formData = new FormData()
    formData.append('file', filePmi)

    try {
      dispatch<any>(uploadPdf({ offerId: offer.id, fileType: 'PMI', formData }))
        .then(unwrapResult)
        .then((data: any) => {
          setUploadStatusPmi('SUCCESS')
          if (showAdditionalServices) {
            handleSubmit()
          } else {
            confirmWithoutService()
          }
        })
    } catch (error: any) {
      console.error('Error uploading file:', error)
      setUploadStatusPmi('ERROR')
      setError(error.message ?? 'ERROR on file upload')
      setAlertText(error ?? 'ERROR on file upload')
    }
  }

  const uploadFeedback = () => {
    switch (uploadStatusWorkOrder || uploadStatusInspection || uploadStatusPmi) {
      case 'UPLOADING':
        return <Typography color="primary">Uploading...</Typography>
      case 'SUCCESS':
        return <Typography color="green">Upload successful!</Typography>
      case 'ERROR':
        return <Typography color="error">Upload failed. Try again.</Typography>
      default:
        return null
    }
  }

  const confirmWithoutService = () => {
    const update_offer = {
      id: offer.id,
      status: 'IN_PROGRESS',
    }
    if (offer.in_mileage) {
      dispatch<any>(updateOfferStatus(update_offer))
        .then(unwrapResult)
        .then((updated_offer: any) => {
          onClose()
        })
        .catch((err: any) => {
          setError(err.message)
          console.log(err)
        })
    }
  }

  const renderDroppableArea = (fileType: string) => (
    <div
      onDragOver={(event) => event.preventDefault()}
      onDrop={(event) => {
        event.preventDefault()
        if (event.dataTransfer.files[0]) {
          if (fileType === 'WORK_ORDER') {
            handleFileSelect(event.dataTransfer.files[0], 'WORK_ORDER')
          } else if (fileType === 'PMI') {
            handleFileSelect(event.dataTransfer.files[0], 'PMI')
          } else if (fileType === 'INSPECTION') {
            handleFileSelect(event.dataTransfer.files[0], 'INSPECTION')
          }
        }
      }}
      style={{
        border: '2px dashed #C4C4C4',
        borderRadius: '4px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        marginBottom: '16px',
        transition: 'background-color 0.3s',
        backgroundColor: '#FAFAFA',
      }}
    >
      <CloudUploadIcon style={{ fontSize: '50px', color: '#C4C4C4' }} />
      <Typography>
        <Button
          variant="text"
          component="span"
          style={{ textTransform: 'none', padding: 0 }}
          color="warning"
          onClick={() => handleUploadClick(fileType)}
        >
          Click to upload
        </Button>{' '}
        or drag and drop
      </Typography>
      <Typography variant="caption">PDF, PNG, JPG, or JPEG (max. 25 MB)</Typography>
      <input
        style={{ display: 'none' }}
        ref={fileType === 'WORK_ORDER' ? fileInputInspectionRef : fileInputPmiRef}
        id="file-input"
        type="file"
        accept="application/pdf, image/png, image/jpeg, image/jpg"
        onChange={(e) => {
          if (e.target.files?.[0]) {
            if (fileType === 'WORK_ORDER') {
              handleFileSelect(e.target.files[0], 'WORK_ORDER')
            } else if (fileType === 'PMI') {
              handleFileSelect(e.target.files[0], 'PMI')
            } else if (fileType === 'INSPECTION') {
              handleFileSelect(e.target.files[0], 'INSPECTION')
            }
          }
        }}
      />
      {fileType === 'WORK_ORDER' && fileWorkOrder && (
        <>
          <Typography variant="body2">{`Selected file: ${fileWorkOrder.name}`}</Typography>
          {uploadFeedback()}
        </>
      )}
      {fileType === 'PMI' && filePmi && (
        <>
          <Typography variant="body2">{`Selected file: ${filePmi.name}`}</Typography>
          {uploadFeedback()}
        </>
      )}
      {fileType === 'INSPECTION' && fileInspection && (
        <>
          <Typography variant="body2">{`Selected file: ${fileInspection.name}`}</Typography>
          {uploadFeedback()}
        </>
      )}
    </div>
  )

  const handleServiceChange = (index: number, field: keyof RecommendedService, value: string) => {
    setRecServices((currentServices) =>
      currentServices.map((service, idx) => {
        if (idx === index) {
          if (field === 'id') {
            const selectedService = services.find((s) => s.id === value)
            return {
              ...service,
              [field]: value,
              name: selectedService ? selectedService.name : '',
              type: '',
              types: selectedService ? selectedService.children : [],
            }
          } else {
            return { ...service, [field]: value }
          }
        }
        return service
      })
    )
  }

  const handleAddService = () => {
    const newService: RecommendedService = {
      id: `service_${recServices.length}`,
      name: '',
      type: '',
      types: [],
      price: 0,
      serviceType: '',
    }
    setRecServices((prevServices) => [...prevServices, newService])
  }

  const handleRemoveService = (serviceId: string, serviceType: string) => {
    setRecServices((prevServices) =>
      prevServices.filter(
        (service) => `${service.id}_${service.type}` !== `${serviceId}_${serviceType}`
      )
    )
    setServicePrices((prevPrices) => {
      const updatedPrices = { ...prevPrices }
      delete updatedPrices[`${serviceId}_${serviceType}`]
      return updatedPrices
    })
  }

  const handleSubmit = async () => {
    const filteredServices = recServices.filter((service) => service.serviceType !== 'DIAGNOSIS')

    if (
      filteredServices.some(
        (service) => !service.name || !servicePrices[`${service.id}_${service.type}`]
      )
    ) {
      setAlertOpen(true)
      return
    }

    setAlertOpen(false)

    const formattedServices = filteredServices.map((service) => ({
      service_id: service.type ? service.type : service.id,
      price:
        parseFloat(
          (servicePrices[`${service.id}_${service.type}`] ?? '0').replace(/[^0-9.-]+/g, '')
        ) || 0,
    }))

    const payload = {
      supply_fee: parseFloat(shopSupplyFees?.replace(/[^0-9.-]+/g, '')) || 0,
      tax: parseFloat(tax?.replace(/[^0-9.-]+/g, '')) || 0,
      services: formattedServices,
    }

    try {
      const diagnosticFile = offer.files.find((file) => file.type === 'ESTIMATE')
      if (offer.files.length > 0 && diagnosticFile && diagnosticFile.is_approved === true) {
        dispatch<any>(createReccomendedServices({ offerId: offer.id, body: payload }))
          .then(unwrapResult)
          .then((data: any) => {
            onSubmit()
            navigate(0)
          })
      } else {
        dispatch<any>(submitTechnicalApproval({ offerId: offer.id, body: payload }))
          .then(unwrapResult)
          .then((data: any) => {
            onSubmit()
            navigate(0)
          })
      }
    } catch (error) {
      console.error('Error uploading file:', error)
      setUploadStatusWorkOrder('ERROR')
      setUploadStatusInspection('ERROR')
      setUploadStatusPmi('ERROR')
    }
  }

  const renderServiceBlocks = () => (
    <>
      {recServices.map((service, index) => (
        <React.Fragment key={`${service.id}_${service.type}_${index}`}>
          <Grid container spacing={2} alignItems="center" mb={2}>
            {service.serviceType === 'DIAGNOSIS' ? (
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  value={
                    services.find(
                      (s) => s.id === recServices[index].id || s.id === recServices[index].type
                    )?.name || undefined
                  }
                  InputProps={{
                    readOnly: true,
                    style: { background: '#fff' },
                  }}
                />
              </Grid>
            ) : (
              <Grid item xs={5}>
                <Autocomplete
                  options={options}
                  value={
                    services.find(
                      (service: any) =>
                        service.id === recServices[index].id ||
                        service.id === recServices[index].type
                    ) || undefined
                  }
                  onChange={(event, newValue: any) => {
                    handleServiceChange(index, 'id', newValue?.id || '')
                  }}
                  onInputChange={handleInputChange}
                  getOptionLabel={(option: any) => option.name}
                  loading={loading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Services"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  blurOnSelect={true}
                />
              </Grid>
            )}

            {service.serviceType !== 'DIAGNOSIS' && (
              <Grid item xs={3}>
                <TextField
                  select
                  fullWidth
                  label="Type"
                  value={service.type}
                  onChange={(e) => handleServiceChange(index, 'type', e.target.value)}
                  disabled={!service.name || !service.types.length}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: '50vh',
                        },
                      },
                    },
                  }}
                >
                  {service.types.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}

            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Price"
                value={servicePrices[`${service.id}_${service.type}`] || ''}
                onChange={(e: any) => handleServicePriceChange(service.id, service.type, e)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  style: { background: '#fff' },
                  readOnly: service.serviceType === 'DIAGNOSIS',
                }}
                inputProps={{
                  maxLength: 9,
                  style: { background: '#fff', height: '17px' },
                  readOnly: service.serviceType === 'DIAGNOSIS',
                }}
              />
            </Grid>

            {service.serviceType !== 'DIAGNOSIS' && (
              <Grid item xs={1}>
                <IconButton
                  onClick={() => handleRemoveService(service.id, service.type)}
                  size="large"
                >
                  <img src={DeleteIcon} alt="Delete" />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </React.Fragment>
      ))}
    </>
  )

  const renderSummary = () => {
    if (recServices.length === 0) {
      return null
    }

    return (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} mt={2} mb={2}>
          <Box display="flex" alignItems="center">
            <Typography
              variant="h6"
              sx={{
                fontFamily: APP_FONT,
                fontWeight: '600',
                color: '#393A3D',
                marginRight: '8px',
              }}
            >
              Subtotal:
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontFamily: APP_FONT,
                fontWeight: '600',
                color: '#DB5D08',
              }}
            >
              {fee && formatPrice(fee)}
            </Typography>
          </Box>
          <Divider sx={{ marginY: '16px !important' }} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Stack spacing={1}>
            <Typography
              sx={{
                fontSize: '16px',
                fontFamily: APP_FONT,
                fontWeight: '600',
                color: '#393A3D',
                pr: 2,
              }}
            >
              Shop supply fees:
            </Typography>
          </Stack>
          <TextField
            value={shopSupplyFees}
            onChange={handleFeeChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              style: { background: '#fff' },
            }}
            inputProps={{
              maxLength: 9,
              style: { background: '#fff', height: '17px' },
            }}
            style={{ width: '30%' }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Stack spacing={1}>
            <Typography
              sx={{
                fontSize: '16px',
                fontFamily: APP_FONT,
                fontWeight: '600',
                color: '#393A3D',
                pr: 2,
              }}
            >
              Tax:
            </Typography>
          </Stack>
          <TextField
            value={tax}
            onChange={handleTaxChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              style: { background: '#fff' },
            }}
            inputProps={{
              maxLength: 9,
              style: { background: '#fff', height: '17px' },
            }}
            style={{ width: '30%' }}
          />
        </Box>

        <Box display="flex" gap={4}>
          <Typography
            variant="h6"
            sx={{
              fontFamily: APP_FONT,
              fontWeight: '600',
              color: '#393A3D',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              mb: 2,
            }}
          >
            Total:
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontFamily: APP_FONT,
              fontWeight: '600',
              color: '#DB5D08',
            }}
          >
            {totalFee && formatPrice(totalFee)}
          </Typography>
        </Box>
      </>
    )
  }

  const renderInspectionFeedbackFile = () => {
    if (!inspectionFeedbackFile) return null

    return (
      <Box
        sx={{
          mt: 2,
          mb: 2,
          p: 2,
          border: 1,
          borderColor: '#FFB38C',
          borderRadius: 'borderRadius',
          bgcolor: 'background.paper',
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2, color: '#FF6600' }}>
          Carma Technician Feedback:
        </Typography>
        <Box
          sx={{
            width: '50%',
            padding: 2,
            boxShadow: 3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <PdfDownloadIcon />
            <Typography variant="body2" sx={{ ml: 2 }}>
              {inspectionFeedbackFile.name} ({inspectionFeedbackFile.size})
            </Typography>
          </Box>
          <Button
            color="warning"
            href={inspectionFeedbackFile.url}
            download
            startIcon={<DownloadIcon />}
            target="_blank"
          />
        </Box>
      </Box>
    )
  }

  const handleFeeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value.replace(/[^0-9]/g, '')
    const number = parseInt(input, 10) / 100

    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(number)
      .slice(1)

    setShopSupplyFees(formattedNumber)
  }

  const handleTaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value.replace(/[^0-9]/g, '')
    const number = parseInt(input, 10) / 100

    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(number)
      .slice(1)

    setTax(formattedNumber)
  }

  const renderAdditionalServicesBlock = () => {
    if (!showAdditionalServices) return null

    return (
      <Stack>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3, mt: 3, color: '#FF6600' }}>
            Additional recommended services
          </Typography>
          <Box>
            {' '}
            <Button
              variant="contained"
              onClick={() => setShowAdditionalServices(false)}
              sx={{
                width: 'fit-content',
                borderRadius: '20px',
                textTransform: 'none',
                fontWeight: 'bold',
                paddingLeft: '32px',
                paddingRight: '32px',
                backgroundColor: '#fff',
                mt: 3,
                color: '#000',
                '&:hover': {
                  backgroundColor: '#e0e0e0',
                },
                '&:active': {
                  backgroundColor: '#grey',
                  color: '#fff',
                },
              }}
            >
              <Close /> <Typography sx={{ ml: 2 }}> Remove additional services</Typography>
            </Button>
          </Box>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Work order authorization report for additional recommended services
            </Typography>
            <Typography sx={{ color: 'text.secondary', marginBottom: 2 }}>
              Includes services required to resolve issues identified during vehicle inspection.
            </Typography>
            {renderDroppableArea('WORK_ORDER')}
            {!isPmi && (
              <>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  Vehicle inspection (optional)
                </Typography>
                {renderDroppableArea('INSPECTION')}
              </>
            )}
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Recommended services
            </Typography>
            <Typography sx={{ color: 'text.secondary', marginBottom: 2 }}>
              List all the services which customer’s vehicle needs. Prices must match the work order
              authorization report.
            </Typography>
            {renderServiceBlocks()}
            <Button
              onClick={handleAddService}
              variant="outlined"
              color="warning"
              sx={{ mt: 1, textTransform: 'none' }}
            >
              Add service
            </Button>
            {renderSummary()}
            {recServices.length > 0 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                {' '}
                <Button
                  variant="contained"
                  color="warning"
                  disabled={
                    !fileWorkOrder ||
                    (uploadStatusWorkOrder !== 'IDLE' && uploadStatusWorkOrder !== 'SUCCESS') ||
                    (isPmi && !filePmi) ||
                    (isPmi && uploadStatusPmi !== 'IDLE' && uploadStatusPmi !== 'SUCCESS')
                  }
                  onClick={handleUpload}
                  sx={{
                    width: 'fit-content',
                    borderRadius: '20px',
                    textTransform: 'none',
                    fontWeight: 'bold',
                    paddingLeft: '32px',
                    paddingRight: '32px',
                  }}
                >
                  Send for approval
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </Stack>
    )
  }

  const isPmi = offer.services.find((service) => service.service.required_data?.includes('PMI'))

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      PaperProps={{ style: { maxHeight: '90vh' } }}
    >
      {isLoading ? (
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 2, height: '120vh' }}
          open={true}
          onClick={() => {}}
        >
          <CircularProgress
            size={60}
            sx={{
              color: '#FF6600',
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-30px',
              marginLeft: '-30px',
            }}
          />
        </Backdrop>
      ) : (
        <>
          <Box>
            <ModalTableHead
              onHide={onClose}
              service_request={{
                slug: offer.consumer_request.slug,
                vehicle: offer.vehicle,
                services: offer.services,
                drop_off_time: offer.drop_off_by,
                is_diagnostic: offer.consumer_request.type === 'WORK_ORDER',
              }}
            />
          </Box>
          {alertOpen && <Alert severity="error">{alertText}</Alert>}
          <DialogContent style={{ overflow: 'auto', maxHeight: '80vh' }}>
            {renderInspectionFeedbackFile()}
            <Stack>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                Requested services
              </Typography>
              {offer.services
                .filter((service) => service.is_approved)
                .map((service, index) => (
                  <Box key={`${service.id}_${service.type}_${index}`}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 1,
                      }}
                    >
                      <Stack spacing={1}>
                        <Typography
                          sx={{
                            fontSize: '16px',
                            fontFamily: APP_FONT,
                            fontWeight: '600',
                            color: '#393A3D',
                            mt: 1,
                          }}
                        >
                          {service.service.parent.parent_id
                            ? service.service.parent.name
                            : service.service.name}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {service.service.parent.parent_id && service.service.name}
                        </Typography>
                        {service.service.required_data?.includes('PMI') && (
                          <a
                            href="https://joincarma-images.s3.amazonaws.com/PMI+final.pdf"
                            download
                            target="_blank"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              textDecoration: 'none',
                              color: '#FF6600',
                            }}
                            rel="noreferrer"
                          >
                            <Typography
                              fontFamily={APP_FONT}
                              fontSize={16}
                              mr={1}
                              style={{
                                fontSize: '16px',
                                fontFamily: APP_FONT,
                                fontWeight: '600',
                                color: '#FF6600',
                              }}
                            >
                              PMI Download Link
                            </Typography>
                            <DownloadIcon color="#FF6600" />
                          </a>
                        )}
                      </Stack>
                      <Typography
                        sx={{
                          fontSize: '20px',
                          fontFamily: APP_FONT,
                          fontWeight: '600',
                          color: '#FF6600',
                        }}
                      >
                        {formatPrice(service.price)}
                      </Typography>
                    </Box>
                    {index < offer.services.length - 1 && <Divider />}
                  </Box>
                ))}
              {isPmi && (
                <>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    PMI report
                  </Typography>
                  <Typography sx={{ color: 'text.secondary', marginBottom: 2 }}>
                    Includes the results of the Preventative Maintenance Inspection (PMI).
                  </Typography>
                  {renderDroppableArea('PMI')}
                </>
              )}
            </Stack>

            <Divider />
            {!showAdditionalServices && (
              <Stack>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: '700',
                    textAlign: 'center',
                    mb: 3,
                    mt: 3,
                    color: '#393A3D',
                  }}
                >
                  Does this vehicle need any other services? If so, use the button below to add
                  them.
                </Typography>

                <Stack sx={{ alignItems: 'center' }}>
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={() => setShowAdditionalServices(true)}
                    sx={{
                      width: 'fit-content',
                      borderRadius: '20px',
                      textTransform: 'none',
                      fontWeight: 'bold',
                      paddingLeft: '32px',
                      paddingRight: '32px',
                    }}
                  >
                    + Recommend additional services
                  </Button>
                  <Button
                    variant="outlined"
                    color="warning"
                    disabled={
                      (isPmi && !filePmi) ||
                      (uploadStatusWorkOrder !== 'IDLE' && uploadStatusWorkOrder !== 'SUCCESS')
                    }
                    onClick={() => {
                      setInspectionWithoutServicesModalOpen(true)
                    }}
                    sx={{
                      width: 'fit-content',
                      borderRadius: '20px',
                      textTransform: 'none',
                      fontWeight: 'bold',
                      paddingLeft: '32px',
                      paddingRight: '32px',
                      mt: 4,
                    }}
                  >
                    Continue without recommending additional services
                  </Button>
                </Stack>
              </Stack>
            )}
            {renderAdditionalServicesBlock()}
          </DialogContent>
          <InspectionWithoutServicesModal
            isOpen={isInspectionWithoutServicesModalOpen}
            setIsOpen={setInspectionWithoutServicesModalOpen}
            offer={offer}
            onClose={() => setInspectionWithoutServicesModalOpen(false)}
            onConfirmWithoutService={() => {
              onConfirmWithoutService()
            }}
          />
          <ErrorHandler error={error} clearError={clearError} />
        </>
      )}
    </Dialog>
  )
}

export default InspectionUploadModal
