import { Button, IconButton, Modal, Typography, styled } from '@mui/material'
import { Box } from '@mui/system'
import ModalTableHeadShort from '../../pages/dashboard/tables/modals/components/table_head_short'
import Copy from '../../assets/images/contentCopy.svg'
import CopyFilled from '../../assets/images/contentCopyFilled.svg'
import { ArrowTooltip } from '../../pages/dashboard/tables/modals/components/ui/arrow_tooltip'
import { useState } from 'react'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#F5F5F5',
  width: 700,
  maxHeight: '90vh',
  overflowY: 'auto',
}

const StyledButton = styled(IconButton)`
  & {
    width: 35px;
    height: 35px;
  }
  ,
  &&:hover {
    width: 35px;
    height: 35px;
    background-color: #fff0eb;
  }
`

export default function CallCustomerModal(props: any) {
  const { isOpen, setIsOpen, offer, onClose } = props
  const [copiedPhone, setCopiedPhone] = useState<string | null>(null)

  const handleCopyPhone = (event: React.MouseEvent, vin: string) => {
    event.stopPropagation()

    navigator.clipboard
      .writeText(vin)
      .then(() => {
        setCopiedPhone(vin)
        setTimeout(() => setCopiedPhone(null), 3000)
      })
      .catch((err) => console.error('Failed to copy text: ', err))
  }
  return (
    <Modal open={isOpen} onClose={() => onClose()}>
      <Box sx={style}>
        <ModalTableHeadShort
          onHide={() => setIsOpen(!isOpen)}
          service_request={{
            slug: offer.slug,
            vehicle: offer.vehicle ?? offer.consumer_request?.vehicle,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'right',
            alignItems: 'right',
            mb: 5,

            width: '100%',

            pb: 4,
            pt: 3,
            pl: 8,
            pr: 8,
          }}
        >
          <Typography
            sx={{
              fontSize: '22px',
              fontFamily: 'Figtree',
              fontWeight: 'bold',
              color: '#FF6600',
              mb: 1,
              mr: 4,
            }}
          >
            IMPORTANT!
          </Typography>
          <Typography
            sx={{
              fontSize: '18px',
              fontFamily: 'Figtree',
              fontWeight: '500',
              mb: 2,
              mr: 4,
            }}
          >
            All diagnostics/inspections must be completed and all recommended services (if any) must
            be added to this order for approval prior to any service being initiated on the vehicle.
          </Typography>
          <Typography
            sx={{
              fontSize: '16px',
              fontFamily: 'Figtree',
              fontWeight: '400',
              textAlign: 'center',
              color: '#171717',

              mr: 4,
            }}
          >
            For any assistance, you can call our Support team:
          </Typography>
          <Box
            sx={{
              textAlign: 'center',
            }}
          >
            <Typography
              component="span"
              sx={{
                fontWeight: 600,
                fontFamily: 'Figtree',
                margin: 0,
                mr: 1,
              }}
            >
              (314) 899-2886
            </Typography>
            <ArrowTooltip title={copiedPhone === '(314) 899-2886' ? 'Copied!' : ''}>
              <StyledButton
                onClick={(event: any) => handleCopyPhone(event, '(314) 899-2886')}
              >
                <img src={copiedPhone === '(314) 899-2886' ? CopyFilled : Copy} alt="" />
              </StyledButton>
            </ArrowTooltip>
          </Box>
          <Box
            component="span"
            display="flex"
            justifyContent="right"
            marginTop={'22px'}
            sx={{ mr: 3 }}
          >
            <Button
              onClick={() => {
                onClose()
              }}
              sx={{ width: 85, height: 30, borderRadius: '20px' }}
              variant="contained"
              color="warning"
            >
              <Typography
                sx={{
                  fontSize: '13px',
                  fontFamily: 'Figtree',
                  fontWeight: 'bold',
                }}
              >
                Got it
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
