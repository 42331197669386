import * as React from 'react'
import { useEffect, useState } from 'react'
import { Alert, Box, Button, CssBaseline, TextField, Toolbar, Typography } from '@mui/material'
import { userIsAuth } from '../../redux/actions/user'
import { unwrapResult } from '@reduxjs/toolkit'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { Stack } from '@mui/system'
import { fetchAccount, updateLogo, updateProfile } from '../../redux/actions/account_settings'
import { APP_FONT } from '../../constants/app_font'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { AppHeader } from '../../components/header/app_header'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useAppDispatch } from '../../redux/store'
import { DragEnter } from '../images/DragEnter'
import styled from 'styled-components'
import { AvatarPicker } from '../images/AvatarPicker'
import { useNavigate } from 'react-router-dom'
import { formatFetchedPhoneNumber } from '../../utils/helper'

const drawerWidth = 240

const Wrapper = styled.div`
  position: relative;
  height: 100%;
`

type CouldImg = {
  id?: string
  thumbnail_url?: string
  url?: string
  order_index?: number
}

export type FileAndURL = File & { url: string } & CouldImg
interface Profile {
  email: string
  branch_name: string
  bio: string
  address: string
  phone_number: string
  mobile_phone_number?: string
  stripe_account_id: string
  stripe_payouts_enabled: boolean
  stripe_link: string
  email_notification: boolean
  text_notification: boolean
  logo_image?: string
}

const Profile = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isLoading, setLoading] = useState(false)
  const scrollRef = React.useRef<any>(null)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)
  const [profile, setProfile] = useState<Profile>()
  const [selectedLogo, setSelectedLogo] = useState<File | null>(null)

  const onLogoPicked = (file: File) => {
    setSelectedLogo(file)
  }

  const handleManagePerks = () => {
    navigate('/perks')
  }

  const submit: SubmitHandler<any> = async (register: Profile) => {
    setError('')
    setLoading(true)

    dispatch(updateProfile(register))
      .then(unwrapResult)
      .then((res: any) => {
        setLoading(false)
        setProfile(res)
        setSuccess(true)
        setError('')
        if (selectedLogo) {
          dispatch(updateLogo({ file: selectedLogo }))
            .then(unwrapResult)
            .then((logoRes: any) => {
              // handle successful logo update
            })
            .catch((logoErr: any) => {
              // handle logo update error
            })
        }
        dispatch(userIsAuth())
      })
      .catch((err: any) => {
        setError(err)
        setSuccess(false)
        setError(err.toString)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const schema = yup.object().shape({
    email: yup.string().email().required('Email is required'),
    branch_name: yup.string().required('Business name is required'),
    bio: yup.string().required('Bio is required'),
    address: yup.string().required('Address is required'),
    phone_number: yup.string().required('Phone number is required'),
    mobile_phone_number: yup.string().nullable(),
  })

  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const validateField = (
    fieldName: 'email' | 'branch_name' | 'bio' | 'address' | 'phone_number' | 'mobile_phone_number'
  ) => {
    trigger(fieldName)
  }

  useEffect(() => {
    dispatch<any>(fetchAccount())
      .then(unwrapResult)
      .then((account: Profile) => {
        setProfile(account)
      })
      .catch((error: string) => {
        console.log('Error fetching account:', error)
      })
  }, [])

  return (
    <Box sx={{ display: 'flex', pt: 10 }} ref={scrollRef}>
      <CssBaseline />
      <AppHeader />
      <Box
        component="main"
        sx={{
          paddingLeft: 6,
          paddingRight: 6,
          paddingTop: 4,
          paddingBottom: 4,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {!!error && (
          <Alert
            severity={!!error ? 'error' : 'success'}
            sx={{ marginBottom: 2, fontFamily: 'Figtree' }}
          >
            {error}
          </Alert>
        )}

        {!!success && (
          <Alert severity="success" sx={{ marginBottom: 5, fontFamily: 'Figtree' }}>
            Profile updated successfully!
          </Alert>
        )}

        <Typography fontWeight={700} fontFamily={APP_FONT} fontSize={32} color={'#FF6600'} mb={3}>
          Profile
        </Typography>

        {!profile ? (
          <CircularProgress color="warning" size={80} sx={{ marginTop: 10 }} />
        ) : (
          <Stack direction={'column'} spacing={2}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: 'white',
                width: 'fit-content',
              }}
            >
              <form style={{ width: '750px' }}>
                <Box display="flex">
                  <Box sx={{ mr: 2 }}>
                    <AvatarPicker src={profile?.logo_image || ''} onFilePicked={onLogoPicked} />
                  </Box>
                  <Box>
                    <Typography
                      fontWeight={600}
                      fontFamily={APP_FONT}
                      fontSize={16}
                      color={'#000000'}
                    >
                      Shop logo
                    </Typography>
                    <Typography
                      fontWeight={400}
                      fontFamily={APP_FONT}
                      fontSize={14}
                      color={'#393A3D'}
                    >
                      Displayed to customers when you make an offer on any request
                    </Typography>
                  </Box>
                </Box>

                <Controller
                  name="branch_name"
                  control={control}
                  defaultValue={profile?.branch_name}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      value={value || ''}
                      onChange={(e) => {
                        onChange(e)
                        validateField('branch_name')
                      }}
                      type="text"
                      label="Business name"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      error={!!errors.branch_name}
                      helperText={errors.branch_name?.message?.toString()}
                      InputLabelProps={{
                        style: { fontFamily: 'Figtree' },
                      }}
                    />
                  )}
                />

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ display: 'inline-block' }}>
                    <Controller
                      name="phone_number"
                      control={control}
                      defaultValue={profile?.phone_number}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          value={formatFetchedPhoneNumber(value)}
                          onChange={(e) => {
                            onChange(e)
                            validateField('phone_number')
                          }}
                          type="text"
                          label="Shop phone number"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          error={!!errors.phone_number}
                          helperText={errors.phone_number?.message?.toString()}
                          InputLabelProps={{
                            style: { fontFamily: 'Figtree' },
                          }}
                          style={{ width: '365px' }}
                        />
                      )}
                    />
                  </div>

                  <div style={{ display: 'inline-block' }}>
                    <Controller
                      name="mobile_phone_number"
                      control={control}
                      defaultValue={profile?.mobile_phone_number ?? null}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          value={formatFetchedPhoneNumber(value as any)}
                          onChange={(e) => {
                            onChange(e)
                            validateField('mobile_phone_number')
                          }}
                          type="text"
                          label="Mobile phone number"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          error={!!errors.mobile_phone_number}
                          helperText={errors.mobile_phone_number?.message?.toString()}
                          InputLabelProps={{
                            style: { fontFamily: 'Figtree' },
                          }}
                          style={{ width: '365px' }}
                        />
                      )}
                    />
                  </div>
                </div>
                <Controller
                  name="email"
                  control={control}
                  defaultValue={profile?.email}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      disabled
                      value={value || ''}
                      onChange={(e) => {
                        onChange(e)
                        validateField('email')
                      }}
                      type="text"
                      label="Email address"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      error={!!errors.email}
                      helperText={errors.email?.message?.toString()}
                      InputLabelProps={{
                        style: { fontFamily: 'Figtree' },
                      }}
                    />
                  )}
                />

                <Controller
                  name="address"
                  control={control}
                  defaultValue={profile?.address}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      value={value}
                      onChange={(e) => {
                        onChange(e)
                        validateField('address')
                      }}
                      type="text"
                      label="Address"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      error={!!errors.address}
                      helperText={errors.address?.message?.toString()}
                      InputLabelProps={{
                        style: { fontFamily: 'Figtree' },
                      }}
                    />
                  )}
                />

                <Controller
                  name="bio"
                  control={control}
                  defaultValue={profile?.bio}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      value={value}
                      onChange={(e) => {
                        onChange(e)
                        validateField('bio')
                      }}
                      type="text"
                      label="Bio"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      error={!!errors.bio}
                      helperText={errors.bio?.message?.toString()}
                      InputLabelProps={{
                        style: {
                          fontFamily: 'Figtree',
                        },
                      }}
                      multiline
                      minRows={7}
                      maxRows={7}
                    />
                  )}
                />

                <Box>
                  <Box>
                    <Typography
                      fontWeight={600}
                      fontFamily={APP_FONT}
                      fontSize={16}
                      color={'#000000'}
                    >
                      Shop perks
                    </Typography>
                    <Typography
                      fontWeight={400}
                      fontFamily={APP_FONT}
                      fontSize={14}
                      color={'#393A3D'}
                      mb={3}
                    >
                      Displayed to customers when you make an offer on any request
                    </Typography>
                  </Box>
                  <Button
                    sx={{
                      borderRadius: 10,
                      backgroundColor: '#FFFFFF',
                      fontSize: 16,
                      fontWeight: 700,
                      textTransform: 'none',
                      fontFamily: 'Figtree',
                      color: '#FF6600',
                      border: '1px solid #FF6600',
                      '&:hover': {
                        color: '#FFFFFF',
                        borderColor: '#1976d2',
                      },
                    }}
                    variant="contained"
                    onClick={handleManagePerks}
                  >
                    Manage perks
                    <ArrowForwardIosIcon sx={{ marginLeft: 1, fontSize: 'medium' }} />
                  </Button>
                </Box>
                <Box width="100%">
                  <Wrapper>
                    <DragEnter />
                  </Wrapper>
                </Box>
                <Stack direction={'row'} alignItems={'center'} spacing={3}>
                  <Button
                    sx={{
                      borderRadius: 10,
                      backgroundColor: '#FF6600',
                      fontSize: 16,
                      fontWeight: 700,
                      textTransform: 'none',
                      fontFamily: 'Figtree',
                      color: '#FFFFFF',
                    }}
                    variant="contained"
                    onClick={handleSubmit(submit)}
                  >
                    Save changes
                  </Button>
                </Stack>
              </form>
            </Box>
          </Stack>
        )}
      </Box>
      {isLoading && (
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
          onClick={() => {}}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Box>
  )
}
export default Profile
